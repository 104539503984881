





























































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { vxm } from '@/store'
import AddTag from '@/components/tag/AddTag.vue'

@Component({
  components: { AddTag },
})
export default class ItemTagDialog extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  private value: boolean

  @Prop({ type: Number, required: true })
  private itemId: number

  @Prop({ type: String, required: true })
  private type: string

  @Prop({ type: Array, required: true })
  private itemTags: Array<string>

  @Prop({ type: Array, required: true })
  private tags: Array<string>

  @Prop({ type: Array, required: true })
  private selectedTags: Array<string>

  private saveLoading = false
  private addNewTagDialog = false
  private updatedTags = []

  public created(): void {
    if (this.value) {
      this.updatedTags = [].concat(this.selectedTags)
    }
  }

  private get visible() {
    return this.value
  }

  private set visible(value) {
    this.$emit('input', value)
  }

  @Watch('value')
  private onValueChange(value) {
    if (value) {
      this.updatedTags = [].concat(this.selectedTags)
    }
  }

  private saveItemTags() {
    this.saveLoading = true

    const data = {
      itemId: this.itemId,
      type: this.type,
      tagIds: this.updatedTags.map((a) => a.id),
    }

    this.$axios
      .post('/v4/site/tags/item', data)
      .then(() => {
        vxm.alert.success({
          content: this.$t('c:common:Successfully saved') as string,
          title: this.$t('c:common:Success') as string,
        })

        this.$emit('update', this.updatedTags)
        this.visible = false
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Error loading types')
      })
      .finally(() => {
        this.saveLoading = false
      })
  }

  private removeItem(index) {
    const pos = this.updatedTags.findIndex((ele) => ele.id === index.id)
    this.updatedTags.splice(pos, 1)
  }

  private onNewTag(newTag) {
    this.tags.push(newTag)
    this.updatedTags.push(newTag)
  }
}
