
































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component({})
export default class EditParcelsDialog extends Vue {
  $refs: Vue['$refs'] & {
    editParcelsForm: {
      validate: any
      reset: any
    }
  }

  @Prop({ type: Boolean, required: false })
  private value: boolean

  @Prop({ type: Array, required: false })
  private parcels: any

  @Prop({ type: String, required: false })
  private transportCompany: string

  private visible = false
  private loading = false

  // ================================================================
  // Manage dialog visibility
  // ================================================================

  // On created, start with the visibility given by the v-model prop
  public created(): void {
    if (this.value) {
      this.visible = true
      if (!this.parcels.length) {
        this.addParcel()
      }
    }
  }

  // If the v-model prop is changed from outside, update our visibility
  @Watch('value')
  private onValueChange(value) {
    this.visible = value
    if (value && !this.parcels.length) {
      this.addParcel()
    }
  }

  // If we programmatically close dialog in here, emit to the v-model outside
  private close() {
    if (!this.loading) {
      this.$emit('input', false)
    }
  }

  private addParcel() {
    this.parcels.push({ parcelNumber: '', tracingLink: '' })
  }
}
