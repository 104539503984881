















































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { vxm } from '@/store'
import { mask } from 'vue-the-mask'

@Component({
  directives: {
    mask,
  },
})
export default class AddTag extends Vue {
  @Prop({ type: Boolean, required: true })
  private value: boolean

  @Prop({ type: String, required: false, default: '' })
  private type: string

  $refs: Vue['$refs'] & {
    form: {
      validate: any
      resetValidation: any
    }
  }

  private saveLoading = false
  private isAdvanced = false

  private types = []

  private colorPickerMask = '!#XXXXXX'
  private backgroundColorPickerMenu = false
  private textColorPickerMenu = false

  private suggestedColors = [
    { color: '#309bff', dark: false },
    { color: '#004baf', dark: true },
    { color: '#38c755', dark: false },
    { color: '#088715', dark: true },
    { color: '#fc3545', dark: false },
    { color: '#bc0515', dark: true },
    { color: '#ffc107', dark: false },
    { color: '#eC7100', dark: true },
    { color: '#e83e8c', dark: false },
    { color: '#8610f2', dark: true },
  ]

  private defaultBackgroundColor = this.suggestedColors[0].color
  private defaultTextColor = this.suggestedColors[0].dark ? '#ffffff' : '#000000'

  private rules = {
    name: null,
    type: null,
  }

  private addNewTagData = {
    name: '',
    backgroundColor: this.defaultBackgroundColor,
    textColor: this.defaultTextColor,
    icon: '',
    type: this.type,
  }

  private textColorOptions = [
    ['#FFFFFF'],
    ['#000000'],
  ]

  private iconDialog = false
  private icons = ['fa-pen', 'fa-plus', 'fa-truck']

  private created() {
    this.rules = {
      name: [
        () =>
          !(this.addNewTagData.name === '' && this.addNewTagData.icon === '') ||
          this.$t('c:add-tag:You must choose a name or an icon'),
      ],
      type: [(v) => !!v || this.$t('c:validation:This field is required')],
    }

    let url = this.mainUrl + '/types'
    if (this.type !== '') {
      url += '?type=' + this.type
    }

    this.$axios
      .get(url)
      .then((response) => {
        this.types = response.data.data
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Error loading types')
      })
  }

  private addNewTag(): void {
    if (this.$refs.form.validate()) {
      this.saveLoading = true

      this.$axios
        .post(this.mainUrl, this.addNewTagData)
        .then((response) => {
          this.addNewTagDialog = false

          this.addNewTagData.name = ''
          this.addNewTagData.type = this.type
          this.addNewTagData.icon = ''
          this.addNewTagData.backgroundColor = this.defaultBackgroundColor
          this.addNewTagData.textColor = this.defaultTextColor
          this.$refs.form.resetValidation()

          this.$emit('newTag', response.data.data)

          vxm.alert.success({
            content: this.$t('c:common:Successfully saved') as string,
            title: this.$t('c:common:Success') as string,
          })
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Error saving')
        })
        .finally(() => {
          this.saveLoading = false
        })
    }
  }

  private get mainUrl() {
    return '/v4/site/tags'
  }

  private get addNewTagDialog() {
    return this.value
  }

  private set addNewTagDialog(value) {
    this.$emit('input', value)
  }

  private get backgroundColorSwatchStyle() {
    return {
      backgroundColor: this.addNewTagData.backgroundColor,
      cursor: 'pointer',
      height: '30px',
      width: '30px',
      borderRadius: this.backgroundColorPickerMenu ? '50%' : '4px',
      transition: 'border-radius 200ms ease-in-out',
      border: '1px solid black',
    }
  }

  private get textColorSwatchStyle() {
    return {
      backgroundColor: this.addNewTagData.textColor,
      cursor: 'pointer',
      height: '30px',
      width: '30px',
      borderRadius: this.textColorPickerMenu ? '50%' : '4px',
      transition: 'border-radius 200ms ease-in-out',
      border: '1px solid black',
    }
  }

  // Make suggestedColors into color picker options, like [ [light,dark], [light,dark] ... ]
  private get backgroundColorOptions() {
    const result = []
    for (let i = 0; i < this.suggestedColors.length; i++) {
      const color = this.suggestedColors[i]
      if (i % 2 === 0) {
        result.push([])
        result[i / 2][0] = color.color
      } else {
        result[(i - 1) / 2][1] = color.color
      }
    }
    return result
  }

  private get suggestedColorsDark() {
    const result = []
    for (let i=0; i < this.suggestedColors.length; i++) {
      const c = this.suggestedColors[i]
      if (c.dark) {
        result.push(c)
      }
    }
    return result
  }

  private get suggestedColorsLight() {
    const result = []
    for (let i=0; i < this.suggestedColors.length; i++) {
      const c = this.suggestedColors[i]
      if (!c.dark) {
        result.push(c)
      }
    }
    return result
  }

  private selectSuggestedColor(color) {
    this.addNewTagData.backgroundColor = color.color
    this.addNewTagData.textColor = color.dark ? '#ffffff' : '#000000'
  }

  private setIcon(icon) {
    this.addNewTagData.icon = icon
    this.iconDialog = false
  }
}
