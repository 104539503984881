





























import { Component, Prop, Vue } from 'vue-property-decorator'
import Order from '@/models/order/Order'

@Component({
  components: {},
})
export default class PaymentData extends Vue {
  @Prop({ type: Object })
  private order: Order

  private created() {}
}
