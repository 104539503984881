






















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { appendSitePrefix, isCurrentSiteId } from '@/utils/routeUtils'
import BookingDialog from '@/components/booking/BookingDialog.vue'
import Booking from '@/models/booking/Booking'
import { vxm } from '@/store'
import DropInBooking from '@/components/booking/DropInBooking.vue'
import Order from '@/models/order/Order'

@Component({
  components: { DropInBooking, BookingDialog },
})
export default class BookingData extends Vue {
  @Prop({ type: Object, required: true })
  private order: Order

  private bookingDialog = false
  private dropInDialog = false
  private bookingLoading = false
  private booking: Booking = null

  private created() {
    this.onBookingIdChanged()
  }

  private onComponentChanged(b) {
    this.$emit('onComponentChanged')
  }

  private get orderId() {
    return this.order?.id
  }

  private get bookingId() {
    return this.order?.bookingId
  }

  private get siteId() {
    return this.order?.siteId
  }

  @Watch('bookingId')
  private onBookingIdChanged() {
    if (this.bookingId) {
      this.$axios
        .get('/v4/site/calendars/any/bookings/' + this.bookingId)
        .then((response) => {
          this.booking = new Booking(response.data.data)
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err)
        })
        .finally(() => {
          this.bookingLoading = false
        })
    }
  }

  private bookAppointment() {
    const backTo = document.location.pathname
    this.$router.push(appendSitePrefix('/bookings/new?back=' + encodeURIComponent(backTo)))
  }

  private openBooking() {
    if (isCurrentSiteId(this.siteId)) {
      this.bookingDialog = true
    } else {
      this.$router.push('/' + this.siteId + '/bookings/' + this.bookingId)
    }
  }
}
