

























































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { vxm } from '@/store'
import ItemTagDialog from '@/components/tag/ItemTagDialog.vue'

@Component({
  components: { ItemTagDialog },
})
export default class ItemTags extends Vue {
  @Prop({ type: Number, required: true })
  private itemId: number

  @Prop({ type: String, required: true })
  private type: string

  @Prop({ type: Boolean, required: false })
  private isReadOnly: boolean

  private loaded = false

  private itemTags = []
  private tags = []
  private selectedTags = []
  private addItemTagDialog = false

  private created() {
    this.$axios
      .get('/v4/site/tags/from-item?itemId=' + this.itemId + '&type=' + this.type + '')
      .then((response) => {
        this.itemTags = response.data.itemTags
        this.tags = response.data.allTags
        this.selectedTags = this.itemTags
        this.loaded = true
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Error loading types')
      })
  }

  private handleUpdate(updatedTags) {
    this.itemTags = updatedTags
    this.selectedTags = updatedTags
  }
}
