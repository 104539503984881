var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loaded)?_c('div',[_vm._l((_vm.itemTags),function(item){return _c('v-chip',{key:item.tagId,staticClass:"mr-1 mb-1 hidden-xs-only",style:({ 'background-color': item.backgroundColor, color: item.textColor })},[(item.icon)?_c('v-icon',{attrs:{"small":"","color":item.textColor,"left":item.name !== ''}},[_vm._v(" "+_vm._s(item.icon)+" ")]):_vm._e(),_vm._v(" "+_vm._s(item.name)+" ")],1)}),_vm._l((_vm.itemTags),function(item){return _c('v-tooltip',{key:item.tagId,attrs:{"disabled":item.name == '',"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"mr-1 mb-1 hidden-sm-and-up",style:({
            'background-color': item.backgroundColor,
            color: item.textColor,
            cursor: item.name != '' ? 'pointer' : 'default',
          })},'v-chip',attrs,false),on),[(item.icon)?[_c('v-icon',{attrs:{"small":"","color":item.textColor}},[_vm._v(" "+_vm._s(item.icon)+" ")])]:[_vm._v(" "+_vm._s(item.name.charAt(0))+" ")]],2)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])])}),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isReadOnly),expression:"!isReadOnly"}],attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();_vm.addItemTagDialog = true}}},[_vm._v(" "+_vm._s(_vm.$t('c:item-tag:Edit Tags'))+" ")]),_c('item-tag-dialog',{attrs:{"item-id":_vm.itemId,"type":_vm.type,"item-tags":_vm.itemTags,"tags":_vm.tags,"selected-tags":_vm.selectedTags},on:{"update":_vm.handleUpdate},model:{value:(_vm.addItemTagDialog),callback:function ($$v) {_vm.addItemTagDialog=$$v},expression:"addItemTagDialog"}})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }