












































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { vxm } from '@/store'

@Component({
  components: {},
})
export default class DropinBooking extends Vue {
  public $refs: Vue['$refs'] & {
    dropInBooking: {
      validate: any
      reset: any
    }
  }

  @Prop({ type: Number, required: false })
  private orderId: number

  @Prop({ type: Number, required: false })
  private tyreHotelWheelChangeId: number

  @Prop({ type: Boolean, required: false })
  private value: boolean

  private visible = false

  private selectedDate = new Date().toISOString().substr(0, 10)
  private minDate = new Date().toISOString().substr(0, 10)

  private selectedService = null
  private selectedPlace = null

  private services = []
  private places = []

  private rememberBookingChoice = false

  private rules = {
    date: null,
    service: null,
    place: null,
  }

  private created() {
    this.rules = {
      date: [(v) => !!v || this.$t('c:validation:This field is required')],
      service: [(v) => !!v || this.$t('c:validation:This field is required')],
      place: [(v) => !!v || this.$t('c:validation:This field is required')]
    }

    this.$axios
      .get('/v4/site/calendars/default')
      .then((response) => {
        const calendar = response.data.data
        if (calendar.id) {
          this.$axios
            .get('/v4/site/calendars/' + calendar.id + '/services?perPage=500&sortBy=name')
            .then((response) => {
              this.services = response.data.data
            })
            .catch((err) => {
              vxm.alert.onAxiosError(err)
            })

          this.$axios
            .get('/v4/site/calendars/' + calendar.id + '/places?perPage=500&sortBy=name')
            .then((response) => {
              this.places = response.data.data
            })
            .catch((err) => {
              vxm.alert.onAxiosError(err)
            })
        }
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err)
      })
  }

  private bookTime() {
    if (this.$refs.dropInBooking.validate()) {
      const data = {
        orderId: this.orderId,
        tyreHotelWheelChangeId: this.tyreHotelWheelChangeId,
        date: this.selectedDate,
        serviceId: this.selectedService,
        placeId: this.selectedPlace,
        rememberBookingChoice: this.rememberBookingChoice,
      }

      this.$axios
        .post('/v4/site/calendars/drop-in', data)
        .then((response) => {
          // todo: maybe change last tyre hotel wheel change outside only, not reload whole component
          this.$emit('update')
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err)
        })
    }
  }

  // If the v-model prop is changed from outside, update our visibility
  @Watch('value')
  private onValueChange(value) {
    this.visible = value
  }
}
