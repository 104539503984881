export default class Customer {
  public id: number
  public customerNumber: string
  public name: string
  public address1: string
  public address2: string
  public zipcode: string
  public city: string
  public state: string
  public country: string
  public email: string
  public phone: string
  public mobile: string
  public type: string

  public constructor(data: Record<string, unknown> = null) {
    if (!data) {
      data = {}
    }
    this.id = data.id ? (data.id as number) : null
    this.customerNumber = data.customerNumber ? (data.customerNumber as string) : null
    this.name = data.name ? (data.name as string) : null
    this.address1 = data.address1 ? (data.address1 as string) : null
    this.address2 = data.address2 ? (data.address2 as string) : null
    this.zipcode = data.zipcode ? (data.zipcode as string) : null
    this.city = data.city ? (data.city as string) : null
    this.state = data.state ? (data.state as string) : null
    this.country = data.country ? (data.country as string) : null
    this.email = data.email ? (data.email as string) : null
    this.phone = data.phone ? (data.phone as string) : null
    this.mobile = data.mobile ? (data.mobile as string) : null
    this.type = data.type ? (data.type as string) : null
  }

  public clone(): Customer {
    const result = new Customer()
    Object.assign(result, this)
    return result
  }
}
