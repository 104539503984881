var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-radio-group',{attrs:{"row":"","dense":""},model:{value:(_vm.carOrCars),callback:function ($$v) {_vm.carOrCars=$$v},expression:"carOrCars"}},[_c('v-radio',{attrs:{"value":"thisCar","label":_vm.$t('c:order-view:This car')}}),_c('v-radio',{attrs:{"value":"allCars","label":_vm.$t('c:order-view:All cars')}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.historyLines,"items-per-page":100,"footer-props":{ showFirstLastPage: true, 'items-per-page-options': [10, 25, 50, 100, 250, -1] }},scopedSlots:_vm._u([{key:"item.order",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$t('Order'))+" "+_vm._s(item.order.id)+" - "+_vm._s(item.order.createdAt))])]}},{key:"item.product",fn:function(ref){
var item = ref.item;
return _vm._l((item.order.products),function(product){return _c('v-row',{key:product.id},[_c('router-link',{attrs:{"to":{}}},[_vm._v(" "+_vm._s(product.eontyreDescription)+" ")])],1)})}},{key:"item.price",fn:function(ref){
var item = ref.item;
return _vm._l((item.order.products),function(product){return _c('v-row',{key:product.id},[_vm._v(" "+_vm._s(product.price)+" ")])})}},{key:"item.supplier",fn:function(ref){
var item = ref.item;
return _vm._l((item.order.products),function(product){return _c('v-row',{key:product.id},[_c('router-link',{attrs:{"to":{}}},[_vm._v(" "+_vm._s(product.supplier.name)+" ")])],1)})}},{key:"item.inStock",fn:function(ref){
var item = ref.item;
return _vm._l((item.order.products),function(product){return _c('v-row',{key:product.id},[_c('router-link',{attrs:{"to":{}}},[_vm._v(" "+_vm._s(product.inStock)+" ")])],1)})}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }