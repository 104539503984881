

























import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

// TODO:
// - Make it a little nicer layout-wise, spearation of customer vs cars etc.
// - Show more info (phone, etc?), but don't make it messy
// - Search in more info (like phone), see backend SearchCustomersWithCarsController
// - However, mind normalization of phone numbers

@Component
export default class CustomerOrCar extends Vue {
  @Prop({ default: null }) private label: string
  @Prop({ type: Object }) private value: string | string[]

  private sync = null
  private count = 0 // nb: not really using this for anything
  private data = []
  private isLoading = false
  private debounceTimer = null

  private get displayLabel(): string {
    return this.label || this.$t('Search customer or car')
  }

  private get input() {
    return this.value
  }

  private set input(value) {
    this.$emit('input', value)
  }

  private get items() {
    // If we've not done a search, but we have a selected value, make a virtual item for it
    if (this.value && (!this.data || this.data.length === 0)) {
      const original = this.makeBackendItem(this.value)
      if (original) {
        this.data.push(original)
      }
    }

    // Make customer-or-car data items from backend data
    const result = []
    for (let i = 0; i < this.data.length; i++) {
      const customer = this.data[i]
      result.push(this.makeCustomerItem(customer))
      for (let j = 0; j < customer.cars.length; j++) {
        result.push(this.makeCarItem(customer, customer.cars[j]))
      }
    }
    return result
  }

  private makeBackendItem(value) {
    if (!value || !value.customer) {
      return null
    }
    const result = Object.assign({}, value.customer)
    result.cars = []
    if (value.car) {
      result.cars[0] = value.car
    }
    return result
  }

  private makeCustomerItem(customer) {
    const original = Object.assign({}, customer)
    delete original.cars
    return {
      type: 'customer',
      id: 'cu-' + customer.id,
      name: customer.name,
      details: '#' + customer.customerNumber + (customer.email ? ' - ' + customer.email : ''),
      customer: original,
      car: null,
    }
  }

  private makeCarItem(customer, car) {
    const original = Object.assign({}, customer)
    delete original.cars
    return {
      type: 'car',
      id: 'ca-' + car.id,
      name: car.licenseplate,
      details: '',
      customer: original,
      car: car,
    }
  }

  private filterItems(item, _queryText, _itemText) {
    // do not filter items, as we do that in backend
    return item
  }

  @Watch('sync')
  private onSearch() {
    clearTimeout(this.debounceTimer)
    this.debounceTimer = setTimeout(() => {
      this.doSearch()
    }, 300)
  }

  private doSearch() {
    this.isLoading = true

    console.log('search')
    this.$axios
      .get('/v4/site/customers-with-cars/search?search=' + encodeURIComponent(this.sync))
      .then((response) => {
        this.count = parseInt(response.data.meta.totalItemsCount) || 0
        this.data = response.data.data
      })
      .catch((err) => {
        console.error('Error searching customer/car:', err)
      })
      .finally(() => {
        this.isLoading = false
      })
  }
}
