
















































































































































import { Vue, Component } from 'vue-property-decorator'
import PageTab from '@/components/ui/PageTab.vue'
import Order from '@/models/order/Order.ts'
import Customer from '@/models/customer/Customer.ts'
import Booking from '@/models/booking/Booking'
import { appendSitePrefix } from '@/utils/routeUtils'
import EmailDialog from '@/components/notification/EmailDialog.vue'
import SmsDialog from '@/components/notification/SmsDialog.vue'
import { vxm } from '@/store'
import CancelDialog from '@/components/order/CancelDialog.vue'
import OfferDialog from '@/components/order/OfferDialog.vue'
import DeliveryNoteDialog from '@/components/order/DeliveryNoteDialog.vue'
import BookingData from '@/components/order/BookingData.vue'
import TyreHotel from '@/views/TyreHotel.vue'
import CustomerData from '@/components/order/CustomerData.vue'
import TyreHotelData from '@/components/order/TyreHotelData.vue'
import ReferencesData from '@/components/order/ReferencesData.vue'
import InternalNoteData from '@/components/order/InternalNoteData.vue'
import DocumentData from '@/components/order/DocumentData.vue'
import CommentData from '@/components/order/CommentData.vue'
import TagsData from '@/components/order/TagsData.vue'
import Tab from '@/models/ui/Tab'
import PaymentData from '@/components/order/PaymentData.vue'
import ProductsTabData from '@/components/order/ProductsTabData.vue'
import HistoryTabData from '@/components/order/HistoryTabData.vue'
import CustomerTabData from '@/components/order/CustomerTabData.vue'
import Car from '@/models/car/Car'
import Driver from '@/models/car/Driver'

@Component({
  methods: { appendSitePrefix },
  components: {
    CustomerTabData,
    TagsData,
    CommentData,
    DocumentData,
    InternalNoteData,
    ReferencesData,
    TyreHotelData,
    CustomerData,
    BookingData,
    OfferDialog,
    CancelDialog,
    SmsDialog,
    EmailDialog,
    PageTab,
    DeliveryNoteDialog,
    PaymentData,
    ProductsTabData,
    HistoryTabData,
  },
})
export default class Details extends Vue {
  private loading = false
  private leftSideShown = true

  private transportCompany = ''

  private emailRecipient = null

  private deliveryNoteDialog = false
  private offerDialog = false
  private cancelDialog = false

  private order: Order = null
  private customer: Customer = null
  private payer: Customer = null
  private driver: Driver = null
  private car: Car = null
  private booking: Booking = null
  private tyreHotel: TyreHotel = null

  private parcels = []

  private activeTab = 0
  private allTabs = ['Products', 'Customer', 'Incoming delivery', 'History']

  async created() {
    await this.load()
  }

  private async load() {
    try {
      if (this.$route.params.orderId !== undefined) {
        const responseOrder = await this.$axios.get(`v4/site/order/${this.$route.params.orderId}`)
        this.order = responseOrder.data.data
        this.order.siteId = parseInt(this.$route.params.siteId)
        await Promise.all([this.getCustomer(), this.getPayer(), this.getCar(), this.getBooking()])
      }
    } catch (error) {
      console.error('Error loading data:', error)
    }
  }

  private get tabs() {
    const result = []
    this.allTabs.forEach((item) => {
      const tab = new Tab()
      if (item === 'Products') {
        tab.icon = 'fas fa-shopping-cart'
      } else if (item === 'Customer') {
        tab.icon = 'fas fa-user'
      } else if (item === 'Incoming delivery') {
        tab.icon = 'fas fa-truck'
      } else {
        tab.icon = 'fas fa-history'
      }
      tab.iconColorNormal = 'light-grey'
      tab.iconColorSelected = 'primary'
      tab.textClassNormal = 'light-grey--text'
      tab.textClassSelected = 'primary'
      tab.backgroundNormalColor = 'light-blue'
      tab.translateText = true
      tab.text = item
      result.push(tab)
    })
    return result
  }

  private onTabChange(tabIndex: number) {
    if (tabIndex === 2) {
      this.$router.push(appendSitePrefix('/U/delivery/main?order_id=' + this.$route.params.orderId))
    } else {
      this.activeTab = tabIndex
    }
  }

  private async getCustomer() {
    if (this.order.customerId) {
      const responseCustomer = await this.$axios.get(`v4/site/customers/${this.order.customerId}`)
      this.customer = responseCustomer.data.data
    }
  }

  private async getPayer() {
    if (this.order?.payerId) {
      const responsePayer = await this.$axios.get(`v4/site/customers/${this.order.payerId}`)
      this.payer = responsePayer.data.data
    }
  }

  private async getCar() {
    if (this.order?.carLicenseplate && this.order?.carLicenseplate !== '') {
      const responseCar = await this.$axios.get(`v4/site/cars/by-plate/${this.order.carLicenseplate}`)
      this.car = responseCar.data.data
      if (this.car.driver) {
        this.driver = this.car.driver
      }
    }
  }

  private async getBooking() {
    if (this.order.bookingId) {
      const responseBooking = await this.$axios.get(`v4/site/calendars/any/bookings/${this.order.bookingId}`)
      this.booking = responseBooking.data.data
    }
  }

  private openDeliveryNoteDialog() {
    this.deliveryNoteDialog = true
  }

  private openOfferDialog() {
    this.offerDialog = true
    this.emailRecipient = this.customer.email
  }

  private openCancelDialog() {
    this.cancelDialog = true
    this.emailRecipient = this.customer.email
  }

  private onComponentChanged() {
    this.$emit('onComponentChanged')
  }

  private isStarredToggle() {
    this.order.isStarred = !this.order.isStarred
  }
}
