















































































import { Vue, Component, Prop } from 'vue-property-decorator'
import DocumentDialog from '@/components/document/DocumentDialog.vue'
import { formatDateTimeString } from '@/utils/dateUtils'
import { vxm } from '@/store'

@Component({
  methods: { formatDateTimeString },
  components: { DocumentDialog },
})
export default class CommentData extends Vue {
  public $refs: Vue['$refs'] & {
    uploadNewDocumentForm: {
      validate: any
      reset: any
    }

    commentForm: {
      validate: any
      reset: any
    }
  }

  @Prop({ type: Number, required: true })
  private orderId: number

  private module = 'mod_order'

  private showInWorkOrder = true

  private addCommentDialog = false

  private deleteConfirmationDialog = false
  private deleteLoading = false
  private deleteCommentId = null
  private hoveredCommentId = null

  private comment = ''
  private comments = []

  async created() {
    await this.load()
  }

  private async load() {
    await this.$axios
      .get('/v4/site/comments?module=' + this.module + '&moduleId=' + this.orderId)
      .then((response) => {
        this.comments = response.data.data
        this.showInWorkOrder = true
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err)
      })
  }

  private addCommentSectionToggle() {
    this.addCommentDialog = !this.addCommentDialog
  }

  private addComment() {
    if (this.$refs.commentForm.validate()) {
      const data = {
        module: this.module,
        moduleId: this.orderId,
        comment: this.comment,
      }
      this.$axios
        .post('/v4/site/comments', data)
        .then(() => {
          this.load()
          this.addCommentDialog = false
          this.$refs.commentForm.reset()
        })
        .catch((err) => {
          console.log(err)
          vxm.alert.onAxiosError(err)
        })
    }
  }

  private deleteCommentToggle(commentId) {
    this.deleteConfirmationDialog = true
    this.deleteCommentId = commentId
  }

  private confirmDelete() {
    this.deleteLoading = true

    this.$axios
      .delete('/v4/site/comments/' + this.deleteCommentId)
      .then(() => {
        vxm.alert.success({
          content: this.$t('c:common:Successfully deleted') as string,
          title: this.$t('c:common:Success') as string,
        })
        this.deleteConfirmationDialog = false
        this.load()
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Error deleting the comment')
      })
      .finally(() => {
        this.deleteLoading = false
      })
  }
}
