import BookingService from '@/models/booking/BookingService'
import BookingResource from '@/models/booking/BookingResource'
import BookingPlace from "@/models/booking/BookingPlace";

export default class BookingDraft {
  public time: string
  public duration: number
  public carId: number
  public customerId: number
  public customerName: string
  public comment: string
  public contactName: string
  public contactEmail: string
  public contactMobile: string
  public carLicenseplate: string
  public primaryService: BookingService
  public addonServices: Array<BookingService>
  public resource: BookingResource
  public place: BookingPlace
  public orderId: number
  public tyreHotelId: number
  public tyreHotelSeason: string
  public customerOrCarInput

  public constructor(data = null) {
    if (!data) {
      data = {}
    }
    this.time = data.time ? (data.time as string) : ''
    this.duration = data.duration ? (data.duration as number) : null
    this.carId = data.carId ? (data.carId as number) : null
    this.customerId = data.customerId ? (data.customerId as number) : null
    this.comment = data.comment ? (data.comment as string) : ''
    this.customerName = data.customerName ? (data.customerName as string) : ''
    this.contactName = data.contactName ? (data.contactName as string) : ''
    this.contactEmail = data.contactEmail ? (data.contactEmail as string) : ''
    this.contactMobile = data.contactMobile ? (data.contactMobile as string) : ''
    this.carLicenseplate = data.carLicenseplate ? (data.carLicenseplate as string) : ''
    this.primaryService = data.primaryService ? (data.primaryService as BookingService) : null
    this.addonServices = data.addonServices ? (data.addonServices as Array<BookingService>) : []
    this.resource = data.resource ? (data.resource as BookingResource) : null
    this.place = data.place ? (data.place as BookingPlace) : null
    this.orderId = data.orderId ? (data.orderId as number) : null
    this.tyreHotelId = data.tyreHotelId ? (data.tyreHotelId as number) : null
    this.tyreHotelSeason = data.tyreHotelSeason ? (data.tyreHotelSeason as string) : ''
    this.customerOrCarInput = data.customerOrCarInput || null
  }
}
