export default class Driver {
  public id: number
  public name: string
  public email: string
  public mobile: string
  public landline: string
  public zipcode: string
  public city: string
  public countryCode: string

  public constructor(data: Record<string, unknown> = null) {
    if (!data) {
      data = {}
    }
    this.id = data.id ? (data.id as number) : null
    this.name = data.name ? (data.name as string) : null
    this.email = data.email ? (data.email as string) : null
    this.mobile = data.mobile ? (data.mobile as string) : null
    this.landline = data.landline ? (data.landline as string) : null
    this.zipcode = data.zipcode ? (data.zipcode as string) : null
    this.city = data.city ? (data.city as string) : null
    this.countryCode = data.countryCode ? (data.countryCode as string) : null
  }

  public clone(): Driver {
    const result = new Driver()
    Object.assign(result, this)
    return result
  }
}
