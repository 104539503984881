import { render, staticRenderFns } from "./Details.vue?vue&type=template&id=4f000b68&scoped=true&"
import script from "./Details.vue?vue&type=script&lang=ts&"
export * from "./Details.vue?vue&type=script&lang=ts&"
import style0 from "./Details.vue?vue&type=style&index=0&id=4f000b68&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f000b68",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCol,VContainer,VDivider,VIcon,VProgressCircular,VRow})
