export default class Site {
  public id: number
  public name: string
  public email: string
  public emailSenderAddress: string
  public emailSenderName: string
  public smsSenderName: string
  public supplierId: number

  public constructor(data: Record<string, unknown> = null) {
    if (!data) {
      data = {}
    }
    this.id = data.id ? (data.id as number) : null
    this.name = data.name ? (data.name as string) : null
    this.email = data.email ? (data.email as string) : null
    this.emailSenderAddress = data.emailSenderAddress ? (data.emailSenderAddress as string) : null
    this.emailSenderName = data.emailSenderName ? (data.emailSenderName as string) : null
    this.smsSenderName = data.smsSenderName ? (data.smsSenderName as string) : null
    this.supplierId = data.supplierId ? (data.supplierId as number) : null
  }

  public clone(): Site {
    const result = new Site()
    Object.assign(result, this)
    return result
  }
}
