













































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { axios } from '@/plugins/vueaxios'
import { vxm } from '@/store'
import Site from '@/models/site/Site'

@Component({})
export default class SmsDialog extends Vue {
  $refs: Vue['$refs'] & {
    sendSmsForm: {
      validate: any
      reset: any
    }
  }

  @Prop({ type: Boolean, required: false })
  private value: boolean

  @Prop({ type: String, required: false })
  private recipient: string

  private visible = false

  private loading = false

  private site: Site = null

  private sendSmsData = {
    sender: null,
    recipients: null,
    body: null,
  }

  private rules = {
    sender: [
      (v) => !!v || this.$t('c:validation:This field is required'),
      (v) =>
        (v && v.length > 1 && v.length < 12) ||
        this.$t('c:validation:Must be between %s and %s characters').replace('%s', '2').replace('%s', '11'),
    ],
    recipients: [
      (v) => (v && v.length > 0) || this.$t('c:validation:This field is required'),
      (v) => this.validateRecipients(v),
    ],
    body: [(v) => !!v || this.$t('c:validation:This field is required')],
  }

  // ================================================================
  // Manage dialog visibility
  // ================================================================

  // On created, start with the visibility given by the v-model prop
  public async created(): Promise<void> {
    if (this.value) {
      this.visible = true
    }

    await axios
      .get('/v4/site/my-site')
      .then((response) => {
        this.site = new Site(response.data.data)
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, this.$t('Could not send SMS'))
      })
  }

  // If the v-model prop is changed from outside, update our visibility
  @Watch('value')
  private onValueChange(value) {
    this.visible = value

    if (this.visible) {
      if (!this.sendSmsData.sender) {
        this.sendSmsData.sender = this.site.smsSenderName ?? 'EONTYRE'
      }
      if ((!this.sendSmsData.recipients || this.sendSmsData.recipients.length === 0) && this.recipient) {
        this.sendSmsData.recipients = [this.recipient]
      }
    }
  }

  // If we programmatically close dialog in here, emit to the v-model outside
  private close() {
    if (!this.loading) {
      this.$emit('input', false)
    }
  }

  private send() {
    if (this.$refs.sendSmsForm.validate()) {
      this.loading = true
      let success = false

      axios
        .post('/v4/site/notification/send/sms', this.sendSmsData)
        .then(() => {
          vxm.alert.success(this.$t('SMS successfully sent'))
          this.$refs.sendSmsForm.reset()
          success = true
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, this.$t('Could not send SMS'))
        })
        .finally(() => {
          this.loading = false
          if (success) {
            this.close()
          }
        })
    }
  }

  protected validateRecipient(phoneNumber: string): boolean {
    const phoneNumberRegex = /^(\+{0,2}\d+|\d+)$/
    return phoneNumberRegex.test(phoneNumber)
  }

  private validateRecipients(value): boolean | string {
    for (const phoneNumber of value) {
      if (!this.validateRecipient(phoneNumber) || phoneNumber.length < 9) {
        return this.$t('c:validation:Invalid phone number format') + ' ' + phoneNumber
      }
    }
    return true
  }

  private get smsParts(): string {
    let parts = 1
    const bodyLength = this.sendSmsData.body?.length

    if (bodyLength > 160) {
      parts = Math.ceil(bodyLength / 153)
    }

    return this.$t('%s part(s). These are estimated values').replace('%s', parts.toString())
  }
}
