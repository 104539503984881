

































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { vxm } from '@/store'
import Order from '@/models/order/Order'
import TyreHotel from '@/models/tyrehotel/TyreHotel'

@Component({
  components: {},
})
export default class TyreHotelData extends Vue {
  @Prop({ type: Object, required: true })
  private order: Order

  private tyreHotel: TyreHotel = null

  private connectTyreHotelDialog = false

  private tyreHotelConnectSelection = 'summer'

  private async created() {
    await this.load()
  }

  private async load() {
    await Promise.all([this.getTyreHotel()])
  }

  private async getTyreHotel() {
    if (this.order?.tyreHotelId) {
      const responseTyreHotel = await this.$axios.get(
        `/v4/site/tyre-hotels-v2/${this.tyreHotelId}` + this.order.tyreHotelId,
      )
      this.tyreHotel = responseTyreHotel.data.data
    }
  }

  private onComponentChanged(b) {
    this.$emit('onComponentChanged')
  }

  private get tyreHotelId() {
    return this.order?.tyreHotelId
  }

  private connectTyreHotelDialogToggle() {
    this.connectTyreHotelDialog = !this.connectTyreHotelDialog
  }

  private openTyreHotel() {
    // todo: fix before using in real time
    // this.$router.push({
    //   name: 'TyreHotel/View',
    //   params: { tyreHotelId: this.getTyreHotel() },
    // })
    return true
  }
}
