




































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Order from '@/models/order/Order'
import ListView from '@/components/list/ListView.vue'
import json from './mockup-order-lines.json'

@Component({
  components: { ListView },
})
export default class ProductsTabData extends Vue {
  @Prop({ type: Object })
  private order: Order

  private listViewKey = 0

  private headers = []
  private rowActions = []

  private orderLines = []

  private editCost = false
  private showProfit = false
  private autoProductsEnabled = true

  private editLine = false
  private editLineDialog = false
  private advanceSearch = false

  private totalNettPrice = 0.0
  private totalGrossPrice = 0.0
  private tyreCount = 3
  private rimCount = 0
  private timeServiceCount = 0.0
  private otherCount = 0

  private searchSelectedOption = 'tyre'

  private searchTip = this.generateSearchTip()

  private async created() {
    await this.getOrderLines()
    this.setHeaders()
    this.rowActions = [
      {
        id: 'edit',
        click: (_, item) => {
          this.editLine = true
          this.editLineDialog = true
        },
      },
    ]
  }

  private async getOrderLines() {
    this.orderLines = json
    json.forEach((item) => {
      this.totalNettPrice += parseFloat(item.nett)
      this.totalGrossPrice += parseFloat(item.gross)
    })
    this.totalNettPrice = parseFloat(this.totalNettPrice.toFixed(2))
    this.totalGrossPrice = parseFloat(this.totalGrossPrice.toFixed(2))
  }

  private setHeaders() {
    this.headers = [
      {
        text: 'Product',
        value: 'product_name',
      },
      {
        text: 'Quantity',
        value: 'quantity',
      },
      {
        text: 'c:order-view:Unit price',
        value: 'price',
      },
      {
        text: 'Discount',
        value: 'discount',
      },
      {
        text: 'c:order-view:Nett',
        value: 'nett',
      },
      {
        text: 'c:order-view:Gross',
        value: 'gross',
      },
      {
        text: 'Status',
        value: 'status',
      },
      {
        text: '',
        value: 'action',
      },
    ]

    if (this.editCost) {
      const costFilter = {
        text: 'c:order-view:Cost',
        value: 'cost',
      }
      const recycleFeeFilter = {
        text: 'c:order-list-view:Recycle fee (in)',
        value: 'recycleFee',
      }
      this.headers.splice(6, 0, costFilter)
      this.headers.splice(7, 0, recycleFeeFilter)
    }
  }

  @Watch('editCost')
  private onEditCostChanged() {
    this.setHeaders()
  }

  private toggleAdvanceSearch() {
    this.advanceSearch = !this.advanceSearch
  }

  private showProfitToggle() {
    this.showProfit = !!this.showProfit
  }

  private isRowDisabled(item) {
    return item.is_auto_product === 1 && this.autoProductsEnabled === true
  }

  private getRowClass(item) {
    return this.isRowDisabled(item) ? 'disabled-row' : ''
  }

  private getAutoProductLabel() {
    return this.autoProductsEnabled
      ? this.$t('c:order-view:Auto products enabled')
      : this.$t('Auto products disabled')
  }

  private generateSearchTip() {
    return `<b>${this.$t('c:order-view:Search tips')}</b><br />
          195.65.15 winter<br />
          1956515 summer rft<br />
          205.45.17 goodyear winter stud<br />
          7x17<br />
          7x17 ET30 steel<br />
          x17<br />
          6.5x<br />
          x17 5x110<br />
          6x16 aluminium<br />
          ABC123<br />
          #93013976 = search art.nr.<br />
          s:12345678 = search supp.art.nr.<br />`
  }
}
