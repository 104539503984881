





























































































































































































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import Booking from '@/models/booking/Booking'
import { vxm } from '@/store'
import { DateTime, Duration } from 'luxon'
import DateField from '@/components/inputs/DateField.vue'
import CustomerOrCar from '@/components/inputs/CustomerOrCar.vue'
import BookingService from '@/models/booking/BookingService'
import BookingDraft from '@/models/booking/BookingDraft'

@Component({
  components: {
    DateField,
    CustomerOrCar,
  },
})
export default class BookingInfo extends Vue {
  @Prop({ type: Booking, required: false })
  private booking: Booking

  @Prop({ type: BookingDraft, required: false })
  private draft: BookingDraft

  @Prop()
  private services

  @Prop()
  private resources

  @Prop()
  private places

  private loading = false
  private confirmDialogVisible = false

  // Timezones

  private timezoneUniversal = 'UTC'
  private timezoneLocal = 'Europe/Oslo' // todo: from backend

  // Inputs for new booking

  private errorMessage = ''
  private dateInput = ''
  private timeInput = ''
  private durationInput = ''
  private customerOrCarInput = null
  private primaryServiceInput = null
  private addonServicesInput = []
  private resourceInput = null
  private placeInput = null
  private draftConnectMode = 'order'
  private draftOrderMode = 'new'
  private draftOrderId = null
  private draftTyreHotels = []
  private draftTyreHotelId: number = null
  private draftTyreHotelSeason = ''
  private draftOrders = []

  private mounted() {
    // If we're creating or editing a new booking draft, try to update state from draft params we got from outside
    this.onUpdatedDraft()
  }

  // ================================================================
  // Click handlers
  // ================================================================

  private clickUpdate() {
    this.loading = true
    const url = '/v4/site/calendars/' + this.booking.calendarId + '/bookings/' + this.booking.id
    this.$axios
      .patch(url, {
        comment: this.booking.comment,
        contactName: this.booking.contactName,
        contactEmail: this.booking.contactEmail,
        contactMobile: this.booking.contactMobile,
      })
      .then((_response) => {
        this.loading = false
        this.$emit('update')
      })
      .catch((err) => {
        this.loading = false
        vxm.alert.onAxiosError(err)
      })
  }

  private clickConfirm() {
    this.loading = true
    const url = '/v4/booking/bookings/' + this.booking.ticket + '/' + this.booking.secret + '/confirm'
    this.$axios
      .post(url)
      .then((_response) => {
        this.loading = false
        this.$emit('update')
      })
      .catch((err) => {
        this.loading = false
        vxm.alert.onAxiosError(err)
      })
  }

  private clickChangeTime() {
    const url = document.location.href.split('://')[1].split('/')
    url.shift()
    const back = '/' + url.join('/')
    this.$router.push({
      name: 'Booking/Modify',
      params: { id: '' + this.booking.id },
      query: { back },
    })
    this.$emit('close')
  }

  private clickCancel() {
    this.confirmDialogVisible = true
  }

  private clickCancelConfirm() {
    this.confirmDialogVisible = false
    this.loading = true
    const url = '/v4/booking/bookings/' + this.booking.ticket + '/' + this.booking.secret + '/cancel'
    this.$axios
      .put(url)
      .then((_response) => {
        this.loading = false
        this.$emit('update')
      })
      .catch((err) => {
        this.loading = false
        vxm.alert.onAxiosError(err)
      })
  }

  private getReminderStatusText(booking) {
    switch (booking.reminderStatus) {
      case 'Pending':
        return ''
      case 'Sent':
        return this.$t('Reminder sent')
      default:
        return this.$t('Reminder not sent')
    }
  }

  private get errorMessageTranslated() {
    if (!this.errorMessage) {
      return ''
    }
    return this.$t(this.errorMessage)
  }

  // ================================================================
  // New booking
  // ================================================================

  private get isDraft() {
    return !!this.draft
  }

  // When ingoing draft changes, we update local input states
  // - This happens on mounted, or when draft prop changes (i.e. a dialog is re-used with a new draft)

  @Watch('primaryServiceInput')
  private onUpdatedPrimaryService() {
    if (this.primaryServiceInput?.mode.indexOf('TyreHotel') !== -1) {
      this.draftConnectMode = 'hotel'
    } else {
      this.draftConnectMode = 'order'
    }
  }

  @Watch('draft', { deep: true })
  private onUpdatedDraft() {
    // Only do these updates if is a draft,
    // else we risk wiping some fields on booking for read-only bookings
    if (!this.isDraft) {
      return
    }

    // ===========================================================================
    // Fields that live in BookingDraft / separate inputs
    // ===========================================================================

    // console.log('incoming draft:' + JSON.stringify(this.draft, null, 4)) // tuba todo remove me

    // Services

    if (this.draft?.primaryService) {
      this.primaryServiceInput = this.draft.primaryService
    } else {
      this.primaryServiceInput = null
    }

    if (this.draft?.addonServices) {
      this.addonServicesInput = this.draft.addonServices
    } else {
      this.addonServicesInput = []
    }

    // Resource

    if (this.draft?.resource) {
      this.resourceInput = this.draft.resource
    } else {
      this.resourceInput = null
    }

    // Place

    if (this.draft?.place) {
      this.placeInput = this.draft.place
    } else {
      this.placeInput = null
    }

    // Time

    if (this.draft?.time) {
      const dt = DateTime.fromISO(this.draft.time, { zone: this.timezoneUniversal })
      this.dateInput = dt.setZone(this.timezoneLocal).toFormat('yyyy-MM-dd')
      this.timeInput = dt.setZone(this.timezoneLocal).toFormat('HH:mm')
    } else {
      this.dateInput = ''
      this.timeInput = ''
    }

    // Duration

    if (this.draft?.duration && this.draft.duration !== parseInt(this.calculatedDuration)) {
      // We only update if it is set *and* it is different from the duration-calculated-from-services
      // This way it will continue to auto-update on changing services unless you set it manually in the input
      this.durationInput = '' + this.draft?.duration
    } else {
      this.durationInput = ''
    }

    // Customer-or-car

    if (this.draft?.customerOrCarInput) {
      this.customerOrCarInput = this.draft.customerOrCarInput
    } else {
      this.customerOrCarInput = null
    }

    // Order and hotel

    if (this.draft?.orderId && this.draftConnectMode === 'order' && this.draftOrderMode === 'existing') {
      this.draftOrderId = this.draft.orderId
      this.draftOrderMode = 'existing'
    } else {
      this.draftOrderId = null
      this.draftOrderMode = 'new'
    }

    if (this.draft?.tyreHotelId && this.draftConnectMode === 'hotel') {
      this.draftTyreHotelId = this.draft.tyreHotelId
    } else {
      this.draftTyreHotelId = null
    }

    if (this.draft?.tyreHotelSeason) {
      this.draftTyreHotelSeason = this.draft.tyreHotelSeason
    } else {
      this.draftTyreHotelSeason = null
    }

    // ===========================================================================
    // Fields that live in Booking
    // ===========================================================================

    // Contact info

    this.booking.contactName = this.draft?.contactName || ''
    this.booking.contactEmail = this.draft?.contactEmail || ''
    this.booking.contactMobile = this.draft?.contactMobile || ''

    // Customer and car
    // todo: update booking or customerAndCar component?

    this.booking.carLicenseplate = this.draft?.carLicenseplate || ''
    this.booking.carId = this.draft?.carId || null
    this.booking.customerId = this.draft?.customerId || null
  }

  // On submit we pass info to the parent, which will update its booking/draft state as needed
  // - If re-opening the same draft from parent, the draft will be passed back to us and handled in onUpdatedDraft

  private clickSubmitNew() {
    // Validate

    if (!this.dateInput) {
      this.errorMessage = 'Must choose date'
      return
    }
    if (!this.timeInput) {
      this.errorMessage = 'Must choose time'
      return
    }
    if (!this.primaryServiceInput) {
      this.errorMessage = 'Must select service'
      return
    }
    if (!this.resourceInput) {
      this.errorMessage = 'Must select resource'
      return
    }
    if (!this.placeInput) {
      this.errorMessage = 'Must select place'
      return
    }
    if (!this.booking.customerId) {
      this.errorMessage = 'Must select customer and/or car'
      return
    }
    if (this.draftConnectMode === 'order') {
      if (this.draftOrderMode === 'existing' && !this.draftOrderId) {
        this.errorMessage = 'Missing order number'
        return
      }
    } else if (this.draftConnectMode === 'hotel') {
      if (!this.draftTyreHotelId) {
        this.errorMessage = 'Missing tyre hotel number'
        return
      }
    }

    const draft = new BookingDraft()

    // Update draft with fields that live in local inputs

    draft.primaryService = this.primaryServiceInput
    draft.addonServices = this.addonServicesInput
    draft.resource = this.resourceInput
    draft.place = this.placeInput
    draft.duration = parseInt(this.durationInput) || parseInt(this.calculatedDuration) || null

    const dt = DateTime.fromISO(this.dateInput + 'T' + this.timeInput, { zone: this.timezoneLocal })
    draft.time = dt.setZone(this.timezoneUniversal).toFormat('yyyy-MM-dd HH:mm:ss').replace(' ', 'T')

    draft.customerOrCarInput = this.customerOrCarInput

    draft.orderId = this.draftOrderId && this.draftOrderMode === 'existing' ? this.draftOrderId : null
    draft.tyreHotelId = this.draftTyreHotelId || null
    draft.tyreHotelSeason = this.draftTyreHotelSeason || ''

    // Update draft with fields that live in booking

    draft.contactName = this.booking.contactName
    draft.contactEmail = this.booking.contactEmail
    draft.contactMobile = this.booking.contactMobile

    draft.customerId = this.booking.customerId
    draft.customerName = this.booking.customerName
    draft.carId = this.booking.carId
    draft.carLicenseplate = this.booking.carLicenseplate

    // Emit

    this.$emit('submitNew', { draft: draft, booking: this.booking })
  }

  private clickDiscard() {
    this.$emit('discard')
  }

  // Customer/car/contact is not via draft, but by updating the local booking,
  // since contact is editable also for non-new bookings,
  // (and customer/car possibly needed to display various things same way as for existing?)

  @Watch('customerOrCarInput')
  private onCustomerOrCarChanged() {
    const contact = this.getContactFromCustomerOrCar()
    const customer = this.customerOrCarInput.customer || {}
    const car = this.customerOrCarInput.car || {}
    this.booking.contactName = contact.name
    this.booking.contactEmail = contact.email
    this.booking.contactMobile = contact.mobile
    this.booking.customerId = customer.id
    this.booking.customerName = customer.name
    this.booking.carId = car.id
    this.booking.carLicenseplate = car.licenseplate
    if (this.booking.carLicenseplate) {
      this.$axios
        .get(
          '/v4/site/calendars/any/portals/admin/lookup-hotels-and-orders?licenseplate=' +
          this.booking.carLicenseplate,
        )
        .then((response) => {
          this.draftTyreHotels = []
          for (let i = 0; i < response.data.data.tyreHotels.length; i++) {
            this.draftTyreHotels.push(response.data.data.tyreHotels[i])
          }
          if (this.draftTyreHotels.length === 1) {
            this.draftTyreHotelId = this.draftTyreHotels[0].id
          } else {
            this.draftTyreHotelId = null
          }
          this.draftOrders = []
          for (let i = 0; i < response.data.data.orders.length; i++) {
            this.draftOrders.push(response.data.data.orders[i])
          }
          if (this.draftOrders.length === 1) {
            this.draftOrderId = this.draftOrders[0].id
          } else {
            this.draftOrderId = null
          }
          this.draftTyreHotelSeason = response.data.data.defaultSeason
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Failed to lookup hotels')
          this.draftTyreHotels = []
          this.draftOrders = []
        })
    }
  }

  private getContactFromCustomerOrCar() {
    const contact = {
      name: '',
      email: '',
      mobile: '',
    }
    if (this.customerOrCarInput) {
      const customer = this.customerOrCarInput.customer
      const driver = this.customerOrCarInput?.car?.driver
      contact.name = driver?.name || customer?.name || ''
      switch (customer.notificationRecipientForEmail) {
        case 'DriverThenCustomer':
          contact.email = driver?.email || customer?.email || ''
          break
        case 'Driver':
          contact.email = driver?.email || ''
          break
        case 'Customer':
          contact.email = customer?.email || ''
          break
        case 'CustomerThenDriver':
          contact.email = customer?.email || driver?.email || ''
          break
      }
      switch (customer.notificationRecipientForMobile) {
        case 'DriverThenCustomer':
          contact.mobile = driver?.mobile || customer?.mobile || ''
          break
        case 'Driver':
          contact.mobile = driver?.mobile || ''
          break
        case 'Customer':
          contact.mobile = customer?.mobile || ''
          break
        case 'CustomerThenDriver':
          contact.mobile = customer?.mobile || driver?.mobile || ''
          break
      }
    }
    return contact
  }

  private get primaryServices(): Array<BookingService> {
    const result = []
    if (this.services) {
      for (let i = 0; i < this.services.length; i++) {
        if (this.services[i].type === 'Primary') {
          result.push(this.services[i])
        }
      }
    }
    return result
  }

  private get addonServices(): Array<BookingService> {
    const result = []
    if (this.services) {
      for (let i = 0; i < this.services.length; i++) {
        switch (this.services[i].type) {
          case 'AddonForAll':
            result.push(this.services[i])
            break
          case 'AddonForSome':
            if (this.primaryServiceInput && this.services[i].isForPrimaryServiceId(this.primaryServiceInput.id)) {
              result.push(this.services[i])
            }
            break
        }
      }
    }
    return result
  }

  private filterServices(item, query, _itemText) {
    if (!query) {
      return true
    }
    const haystacks = [item.name.toLowerCase(), item.code.toLowerCase()]
    const parts = query.split(' ')
    for (let i = 0; i < parts.length; i++) {
      let partMatched = false
      for (let j = 0; j < haystacks.length; j++) {
        if (!haystacks[j]) {
          continue
        }
        if (haystacks[j].indexOf(parts[i].toLowerCase()) !== -1) {
          partMatched = true
          break
        }
      }
      if (!partMatched) {
        return false
      }
    }
    return true
  }

  private filterResource(item, query, _itemText) {
    if (!query) {
      return true
    }
    const haystacks = [item.name.toLowerCase()]
    const parts = query.split(' ')
    for (let i = 0; i < parts.length; i++) {
      let partMatched = false
      for (let j = 0; j < haystacks.length; j++) {
        if (!haystacks[j]) {
          continue
        }
        if (haystacks[j].indexOf(parts[i].toLowerCase()) !== -1) {
          partMatched = true
          break
        }
      }
      if (!partMatched) {
        return false
      }
    }
    return true
  }

  private filterPlace(item, query, _itemText) {
    if (!query) {
      return true
    }
    const haystacks = [item.name.toLowerCase()]
    const parts = query.split(' ')
    for (let i = 0; i < parts.length; i++) {
      let partMatched = false
      for (let j = 0; j < haystacks.length; j++) {
        if (!haystacks[j]) {
          continue
        }
        if (haystacks[j].indexOf(parts[i].toLowerCase()) !== -1) {
          partMatched = true
          break
        }
      }
      if (!partMatched) {
        return false
      }
    }
    return true
  }

  private get calculatedDuration() {
    let sum = 0
    if (this.primaryServiceInput?.duration) {
      sum += this.primaryServiceInput.duration || 0
    }
    if (this.addonServicesInput) {
      for (let i = 0; i < this.addonServicesInput.length; i++) {
        sum += this.addonServicesInput[i].duration || 0
      }
    }
    return sum > 0 ? '' + sum : ''
  }

  private get endTimeString() {
    if (!this.dateInput || !this.timeInput) {
      return ''
    }
    const d = DateTime.fromISO(this.dateInput + 'T' + this.timeInput + ':00')
    if (!d.isValid) {
      return ''
    }
    const duration = this.durationInput || this.calculatedDuration
    const dur = Duration.fromISO('PT' + duration + 'M')
    if (!dur.isValid) {
      return ''
    }
    return d.plus(dur).toFormat('HH:mm')
  }
}
